<template>
  <div class="home">
    <a-breadcrumb>
      <a-breadcrumb-item href="">
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item href="">
     <a-icon type="setting" />
        <span>系统管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item> 修改密码 </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="search-list-box baff">
      <a-form-model ref="rulepassForm" :model="password" :rules="passwordRules" :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
       style="width: 400px">
      	<a-row :gutter="24">
      		<a-col :span="24">
      			<a-form-model-item label="密码：" prop="password">
      				<a-input v-model="password.password" placeholder="请输入密码" type="password" />
      			</a-form-model-item>
      		</a-col>
      		<a-col :span="24">
      			<a-form-model-item label="确定密码：" prop="npassword">
      				<a-input v-model="password.npassword" placeholder="请输入确定密码" type="password" />
      			</a-form-model-item>
      		</a-col>
			<a-col :span="24">
			  <a-button
			      type="primary"
			      class="marginr20"
			      style="margin-left: 130px"
			      html-type="submit"
				  @click="updatePassword"
			  >
			  确定
			</a-button>
			</a-col>
      	</a-row>
      </a-form-model>
    </div>

    
  </div>
</template>

<script>
	import http from '../http'
export default {
  data() {
    return {
      password: {
      	password: '',
      	npassword: ''
      },
      passwordRules: {
      	password: [{
      		required: true,
      		message: '请输入密码',
      		trigger: 'blur'
      	}, ],
      	npassword: [{
      		required: true,
      		validator: this.validatePass,
      		trigger: 'blur'
      	}, ],
      },
    };
  },
 
  methods: {
    validatePass(rule, value, callback) {
    	if (this.password.password != this.password.npassword) {
    		callback(new Error('两次密码不相同，请确定'));
    	} else {
    		callback();
    	}
    },
	updatePassword() {
		this.$refs.rulepassForm.validate(valid => {
			if (valid) {
				http({
					method: 'post',
					url: '/api.admin.update.userpwd/',
					data: this.password
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('密码修改成功');
					} else {
						this.$message.error(res.message);
					}
				})
			} else {
				this.$message.warning('请检查是否已经输入必填项!');
				return false;
			}
		});
	},
  },
};
</script>
<style  lang="less">
    .baff {
        background-color: #fff;
        margin-top: 16px;
        padding-bottom: 100px;
        padding-top: 50px;
    }
    .nesw {
        margin: 0px;
        font-size: 12px;
        color: red;
        line-height: 16px;
    }
</style>
